// this is a singular 'project' page

import { kebabCase } from 'lodash';
import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"

import {makeName} from '../components/utils.js'
import {MainList} from '../components/utils.js'

import External from '../assets/external_link.svg';

const CategoryList = ({post}) => {
	return (
		<div>
			<div className={"spacer s2"}></div>
			<hr/>
			<h2>Categories</h2>
			{post.frontmatter.category ? (
			<ul className="tagList">
				{post.frontmatter.category.map(category => (
				<Link key={category + `category`} to={`/categories/${kebabCase(category)}/`}>
					<li>
						{category}
					</li>
				</Link>
				))}
			</ul>
			) : null}
			<div className={"spacer s0"}></div>
			<h2>Collaboration</h2>
			{post.frontmatter.collaboration ? (
			<ul className="tagList">
				{post.frontmatter.collaboration.map(collaboration => (
				<Link key={collaboration + `collaboration`} to={`/collaborations/${kebabCase(collaboration)}/`}>
					<li>
						{makeName(collaboration)}
					</li>
				</Link>
				))}
			</ul>
			) : null}
			<div className={"spacer s0"}></div>
			<h2>Technology</h2>
			{post.frontmatter.collaboration ? (
			<ul className="tagList">
				{post.frontmatter.technology.map(technology => (
				<Link key={technology + `technology`} to={`/technology/${kebabCase(technology)}/`}>
					<li>
						{technology}
					</li>
				</Link>
				))}
			</ul>
			) : null}
			<div className={"spacer s0"}></div>
			<hr/>
		</div>
	)
}

const ExternalLink = ({link}) => {
	return link ?
		(<a className="externalLink" href={link}>
 				<div className="ext1">Visit Application</div>
 				<External/>
		</a>) : <div></div>
}

const Project = ({ data }) => {
	const post = data.project
	const totalCount = data.projects.totalCount
	const totalTalk = data.talks.totalCount
	return (
		<Layout>
			<div className={"container"}>
				<MainList total={totalCount} totalTalk={totalTalk}/>
				<h1>{post.frontmatter.title}</h1>
				<p>{post.frontmatter.date}</p>
				<ExternalLink link={post.frontmatter.link}/>
				<div dangerouslySetInnerHTML={{ __html: post.html }} />
				<ExternalLink link={post.frontmatter.link}/>
				<CategoryList post={post}/>
				<MainList total={totalCount} totalTalk={totalTalk}/>
			</div>
		</Layout>
	)
}

export default Project;

export const query = graphql`
	query($slug: String!) {
		projects: allMarkdownRemark(
				limit: 2000
				filter: {frontmatter: {template: {eq: "project"}}}
			) {
				totalCount
			},
		talks: allMarkdownRemark(
				limit: 2000
				filter: {frontmatter: {template: {eq: "talk"}}}
			) {
				totalCount
			},
		project: markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				title
				category
				collaboration
				technology
				link
				date(formatString: "MMMM DD, YYYY")
			}
		}
	}
`